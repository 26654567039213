@use "src/shared/styles/variables/typography";

.table {
	tbody > tr > td {
		padding: 1.5rem .5rem;

		&:nth-child(2) {
			width: 45%;
		}

		&:first-child {
			padding-left: 2rem;
		}

		&:last-child {
			padding-right: 2rem;
		}
	}
}

.name {
	font-weight: 600;
}

.position {
	@include typography.font-body-2;
	color: var( --color-secondary-3);
}

.removeButton {
	cursor: pointer;
	fill: var(--color-secondary-2);

	&:hover {
		fill: var(--color-secondary-3);
	}

	&:active {
		fill: var(--color-secondary-3);
	}
}
